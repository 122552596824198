.edit-icons-container {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .edit-icons {
        display: flex;
        flex-direction: column;
        padding-top: 7px;
        padding-right: 7px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
    }
}
