.bp3-navbar.filter-topbar {
    position: fixed;
    top: 0;
    left: 510px;
    width: 420px;
    box-shadow: none;
    padding: 0;
    background: transparent;

    .select-user-label {
        margin: 0px 10px 0px 5px;
    }

    .select-user {
        margin: 0 15px 0 0;
    }

    .select-user {
        .bp3-button-text {
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
            max-width: 90px;
            overflow: hidden;
            max-height: 34px;
        }
    }

    .show-active-checkbox,
    .show-deleted-checkbox {
        margin: 0px 10px;
    }
}
