.list-view-container {
    padding: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    .bp3-popover-wrapper,
    .bp3-popover-target {
        display: flex;
    }

    .list-view-item-create-new,
    .list-view-item {
        margin: 0 15px 15px 0;
        display: flex;
        align-items: stretch;
    }

    .list-view-item-create-new-card,
    .list-view-item-card {
        position: relative;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 240px;
    }

    %list-view-item-banner-shared {
        position: absolute;
        left: -20px;
        right: -20px;
        text-align: center;
        font-size: 1.5em;
        color: #efefef;
        transform: rotate(-20deg);
    }

    .list-view-item-deleted {
        @extend %list-view-item-banner-shared;
        background: rgba(255, 0, 0, 0.9);
        top: calc(50% - 15px);
    }

    .list-view-item-near-purge {
        @extend %list-view-item-banner-shared;
        background: rgba(255, 0, 0, 0.5);
        top: calc(30% - 15px);
    }

    .list-view-item-create-new-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .list-view-item-create-new-text {
        font-size: 1.2em;
    }

    .list-view-item-description {
        text-align: center;
        margin: 5px 0 10px 0;
    }

    .list-view-item-create-new-thumbnail,
    .list-view-item-thumbnail {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .list-view-item-thumbnail {
        background: rgba(233, 233, 233, 0.5);
    }

    .list-view-item-create-new-thumbnail {
        margin: 0px 0px 30px 12px;
    }

    .list-view-item-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 3px;
    }

    .list-view-item-button-container {
        display: flex;

        button {
            margin: 0 0 0 10px;

            &:first-child {
                margin: 0;
            }
        }
    }
}

/* Hack for checkboxes to show ticker icon
   https://github.com/palantir/blueprint/issues/2821
 */
.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
