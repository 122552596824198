.template-text-editor {
    background: none;
    line-height: 1.28;

    h1,
    h2,
    h3 {
        font-family: 'Kaisei Opti', 'Open Sans', Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.1em;
        margin: 0 0 0.27em 0;
        margin-block-start: 0;
        margin-block-end: 0.27em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.2em;
    }

    .template-text {
        white-space: pre-line !important;
        font-size: 14px;
    }

    .template-text-large-font-size {
        white-space: pre-line !important;
        font-size: 20px;
    }

    .text-editor-container {
        margin-right: 1px; // hack to fix outline in template4

        &:hover {
            background: none;
            outline: 1px solid lightgray;
            cursor: text;
        }
    }

    .public-DraftEditor-content {
        overflow: hidden;
    }
}
