.template-image-container {
    background: #f7f7f7;

    .image-missing-tooltip {
        width: 100%;
        height: 100%;
    }

    .image-missing-container {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            max-width: 20%;
        }
    }
    // unused .icon-and-image-container {}
    .single-icon {
        position: absolute;
        z-index: 2;
    }
}
