.bp3-dialog.icon-placer-dialog {
    width: auto;
    -webkit-user-drag: none;

    .icon-placer-dialog-body {
        display: flex;
    }

    .icon-placer-image-container {
        overflow: hidden;
    }

    .icon-placer-image {
        position: absolute;
    }

    .icon-placer-image-remove-icon {
        position: absolute;
        right: -27px;
        top: 0;
    }

    $size-icon-top: 0;
    $size-icon-left: -28px;

    .icon-placer-image-minus-icon {
        position: absolute;
        left: $size-icon-left;
        top: $size-icon-top;
    }

    .icon-placer-image-plus-icon {
        position: absolute;
        left: $size-icon-left;
        top: $size-icon-top + 28px;
    }

    .icon-placer-library {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 300px;
        max-width: 350px;
    }

    .icon-placer-library-card {
        margin: 0 0 10px 10px;
        padding: 0;
        border-radius: 0;
    }

    .icon-placer-library-card-frame {
        margin: 10px;
        background: #f0f0f0f0;
    }

    .icon-placer-library-card-image {
        -webkit-user-drag: element;
        width: 120px;
        padding: 10px;
    }
}
