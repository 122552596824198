.login-container {
    display: flex;

    .login-card {
        margin: 30px auto;
        min-width: 30em;
        max-width: 60em;
    }

    .login-image-container {
        display: flex;
        align-items: center;
    }

    .login-image {
        margin: auto;
        max-width: 200px;
    }

    .login-topic {
        text-align: center;
        margin-bottom: 1em;
    }

    .login-forgot-password {
        margin-top: 5mm;
    }
}
