.estate-page {
    position: relative;

    .estate-page-number {
        position: fixed;
        top: 7px;
        left: calc(50% + 80px);
        width: 120px;
        border: 3px solid #106ba3;
        border-radius: 12px;
        background: #106ba3;
        color: white;
        z-index: 14;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        opacity: 0.9;

        span {
            padding: 4px 0;
        }
    }
}

.estate-container {
    position: relative;

    $top-navbar-height: 50px;
    $sidebar-width: 270px;

    .estate-sidebar-container {
        position: fixed;
        top: $top-navbar-height;
        bottom: 0px;
        left: 0px;
        width: $sidebar-width;
        background: white;
    }

    .estate-page-editor-container {
        position: fixed;
        top: $top-navbar-height;
        right: 0px;
        left: $sidebar-width;
        bottom: 0px;
        background: rgb(240, 240, 240);
        display: flex;
        align-items: stretch;
    }

    $edit-name-height: 95px;

    .estate-sidebar-edit-name-container {
        position: fixed;
        top: $top-navbar-height;
        left: 0px;
        height: $edit-name-height;
        width: $sidebar-width;
    }

    $toolbar-height: 55px;

    .estate-sidebar-toolbar {
        position: fixed;
        top: $top-navbar-height + $edit-name-height;
        left: 0px;
        height: $toolbar-height;
        width: $sidebar-width;
    }

    .estate-sidebar-buttons {
        padding: 5px 10px;
        display: flex;
        justify-content: flex-end;
        & > .bp3-popover-wrapper {
            flex: 0 1 auto;
        }
    }

    .estate-sidebar-edit-name {
        padding: 10px 10px 5px 10px;
        font-size: 20px;
    }

    .estate-sidebar-thumbnails {
        position: fixed;
        top: $top-navbar-height + $toolbar-height + $edit-name-height;
        left: 10px;
        bottom: 0px;
        width: 260px;
        margin: 0 auto;
        background: rgb(240, 240, 240);
        overflow-y: scroll;
    }

    .estate-page-editor-container {
        overflow: auto;
    }

    .estate-page-editor-inner-container {
        margin: 20px auto;
    }
}
/* some scrollbar css magic for webkit browsers */
::-webkit-scrollbar {
    width: 10px;
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    border: 2px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}
