.bp3-overlay.loading-overlay {
    .loading-overlay-backdrop {
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        transition: opacity 0.5s ease;

        &.loading-overlay-transition-enter-done {
            opacity: 1;
        }
    }

    .loading-overlay-spinner-container {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;

        opacity: 0;
        transition: opacity 0.3s ease;

        &.loading-overlay-transition-enter-done {
            opacity: 1;
        }
    }
}
