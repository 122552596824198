.bucket-fill-icon {
    width: 20px;

    .bucket-fill-icon-text-background {
        fill: #dadada;
    }

    .bucket-fill-icon-text {
        fill: #888;
    }

    .bucket-fill-icon-bucket-handle {
        fill: #757575;
    }

    .bucket-fill-icon-bucket-empty {
        fill: #efefef;
    }

    .bucket-fill-icon-bucket-paint {
        fill: #bababa;
    }

    &.mod-alpha-00 {
        .bucket-fill-icon-bucket-paint,
        .bucket-fill-icon-text-background {
            opacity: 0.1;
        }
    }

    &.mod-alpha-05 {
        .bucket-fill-icon-bucket-paint,
        .bucket-fill-icon-text-background {
            opacity: 0.5;
        }
    }

    &.mod-active {
        .bucket-fill-icon-text {
            fill: #000;
        }
    }
}
