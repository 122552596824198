.page-edit-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .page-edit-card {
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
}
