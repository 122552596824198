@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;700');

@import '@blueprintjs/core/lib/css/blueprint.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    background: #f5fafd;
}

a:hover {
    text-decoration: none;
}
