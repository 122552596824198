/*.top-navbar button {}
*/
.top-navbar-logo-container {
    height: 50px;
    width: 240px;

    .top-navbar-logo {
        display: block;
        margin: auto;
        max-height: 100%;
        padding: 5px 0px;
    }
}
