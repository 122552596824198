.invert-colors-icon {
  width: 20px;
  .invert-colors-icon-rectangle {
    fill: #000;
    stroke: #000;
    stroke-width: 40px;
  }

  .invert-colors-icon-text {
    fill: #fff;
  }

  &.mod-active {
    .invert-colors-icon-rectangle {
      fill: #fff;
      stroke: #000;
      stroke-width: 40px;
    }

    .invert-colors-icon-text {
      fill: #000;
    }

  }
}