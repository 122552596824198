.text-editor-controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: -37px;
    background: none;

    width: 100%;

    .bp3-button-group {
        margin-right: 5px;
    }

    .bp3-button.text-editor-style-button {
        margin: 0;
        min-width: 30px;

        .bp3-icon,
        .bp3-button-text {
            color: #aaa;
        }
        .bp3-button-text {
            font-weight: 700;
        }

        &.mod-active {
            .bp3-icon,
            .bp3-button-text {
                color: #000;
            }
        }

        &.mod-fill {
            padding: 3px 0 0 2px;
        }
    }
}
